import React, { FC, useCallback, useEffect, useState } from 'react';
import history from 'utils/history';
import Button from 'components/Button/Button';
import FormGroup from 'components/Form/FormGroup';
import InputField from 'components/Form/InputField';
import Label from 'components/Form/Label/Label';
import useLogin from './hooks';
import { AuthContext } from './types';

const Login: FC<{
  onSuccess: (a: AuthContext) => void;
}> = ({ onSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(true);

  const {
    isLoginRequest,
    isLoginSuccess,
    isLoginFailure,
    isVerified,
    email,
    login,
  } = useLogin();

  useEffect(() => {
    if (
      isLoginSuccess &&
      isVerified &&
      !history?.location?.pathname.includes('/partner')
    ) {
      history?.push('/accounts');
    }
  }, [isLoginSuccess, isVerified]);

  useEffect(() => {
    if (email) setUsername(email);
  }, [email]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!isLoginRequest && username && password) {
        login({ username, password }).then((a) => {
          if (a && typeof onSuccess === 'function') onSuccess(a);
        });
      }
      if (!showError) setShowError(true);
    },
    [isLoginRequest, username, password, showError, setShowError],
  );

  return (
    <form onSubmit={handleSubmit} method="post">
      <FormGroup error={showError && isLoginFailure?.username} field="username">
        <InputField
          id="username"
          name="username"
          type="email"
          defaultValue={username}
          onChange={(e) => {
            setUsername(e?.target?.value || '');
          }}
        />
        <Label htmlFor="username" hasValue={!!username}>
          Email
        </Label>
      </FormGroup>
      <FormGroup error={showError && isLoginFailure?.password} field="password">
        <InputField
          id="password"
          name="password"
          type="password"
          onChange={(e) => {
            setPassword(e?.target?.value || '');
          }}
          autoComplete="new-password"
        />
        <Label htmlFor="password" hasValue={!!password}>
          Password
        </Label>
      </FormGroup>
      <FormGroup style={{ textAlign: 'center' }}>
        <Button
          style={{ width: '185px', display: 'inline' }}
          type="submit"
          data-test-id="LoginButton"
          disabled={!username || !password || isLoginRequest}
          stopPropagation={false}
          preventDefault={false}
        >
          Login
        </Button>
      </FormGroup>
    </form>
  );
};

export default Login;
